import { ChangeEvent, FC, useEffect, useRef, useState, KeyboardEvent } from "react";
import CustomSelect from ".";
import { CustomSelectControllerInterface, CustomSelectOptionType } from "./indexModel";
import { useTranslation } from "react-i18next";
import { nanoid } from 'nanoid'
import emitter from "../../core/shared/emitter";

const CustomSelectController: FC<CustomSelectControllerInterface> = (props) => {
  const { t } = useTranslation('CustomSelect');

  const [isOpened, setIsOpened] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const inputSearchRef = useRef<HTMLInputElement>(null);

  // Filter
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isFiltered, setIsFiltered] = useState(false);

  const [selectPopupStyle, setSelectPopupStyle] = useState<any>({});
  const [selectUniqueId, setSelectUniqueId] = useState('');

  const [filteredOptions, setFilteredOptions] = useState<CustomSelectOptionType[]>(props.options ? [...props.options] : []);

  useEffect(() => {
    const selectHashId = nanoid();
    setSelectUniqueId(selectHashId);

    emitter.on('clear-all-search-custom-select', () => {
      handleClearSearch();
    });

    return () => {
      emitter.off('clear-all-search-custom-select');
    }
  }, []);

  useEffect(() => {
    if (props.hasSimpleFilter && !searchTerm) {
      setFilteredOptions(props.options);
    }
  }, [props.options, searchTerm]);

  useEffect(() => {
    if (props.isMultiselect) {
      if (Object.hasOwn(props, 'setSelectedMulti')) {
        props.setSelectedMulti(props.selectedMulti || []);
      }
      if (Object.hasOwn(props, 'setCustomSelectedsetCustomSelected') && props.id) {
        props.setCustomSelected(props.id, props.selectedMulti || []);
      }
    } else {
      if (props.selected) {
        if (Object.hasOwn(props, 'setSelected')) {
          props.setSelected(props.selected);
        }
        if (Object.hasOwn(props, 'setCustomSelected') && props.id) {
          props.setCustomSelected(props.id, props.selected);
        }
      }
    }
  }, [props.selected, props.selectedMulti]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if ((selectRef?.current && !selectRef?.current?.contains(target)) &&
        (popupRef?.current && !popupRef?.current?.contains(target))) {
        setIsOpened(false);
      }
    }

    if (selectRef?.current) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('resize', formatPopupPosition);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', formatPopupPosition);
      // if (resizeTimeout.current) {
      //   clearTimeout(resizeTimeout.current);
      // }
    }
  }, [selectRef?.current, popupRef?.current]);

  useEffect(() => {
    if (props.isMultiselect) {
      if (props.selectedMulti?.length > 0) {
        setHasSelected(true);
      } else {
        setHasSelected(false);
      }
    } else {
      if (props.selected && props.selected.id) {
        setHasSelected(true);
      } else {
        setHasSelected(false);
      }
    }
  }, [props.selected, props.selectedMulti]);

  useEffect(() => {
    if (props.dropdownPosition === 'body') {
      if (isOpened) {
        if (inputSearchRef.current && !props.loading) {
          inputSearchRef.current.focus();
        }
        if (document.querySelector('.modal')) {
          // Desativar o gerenciamento de foco do Bootstrap temorariamente
          document.querySelector('.modal')?.removeAttribute('tabindex');
        }
      } else {
        if (document.querySelector('.modal')) {
          // Restaurar o gerenciamento de foco do Bootstrap
          document.querySelector('.modal')?.setAttribute('tabindex', '-1');
        }
      }
      formatPopupPosition();
    } else {
      if (isOpened) {
        if (inputSearchRef.current && !props.loading) {
          inputSearchRef.current.focus();
        }
      }
    }
  }, [isOpened]);

  // const debouncedCalculateDropdownStyles = () => {
  //   if (resizeTimeout.current) {
  //     clearTimeout(resizeTimeout.current);
  //   }
  //   resizeTimeout.current = setTimeout(formatPopupPosition, 100);
  // };

  const formatPopupPosition = () => {
    if (selectRef?.current) {
      const bottomDistanceLimit = 20; // Distância limite inferior para que o dropdown seja aberto
      const marginTop = 10; // margem superior quando o dropdown abre para cima

      const rect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = popupRef.current ? popupRef.current.offsetHeight : 0;
      const viewportHeight = window.innerHeight - bottomDistanceLimit;
  
      const topPosition = rect.bottom + window.scrollY;
      const bottomSpace = viewportHeight - rect.bottom;
      const topSpace = rect.top;
  
      let top: number;
      let left: number = rect.left + window.scrollX;
  
      if (bottomSpace < dropdownHeight && topSpace > dropdownHeight) {
        top = props.dropdownPosition === 'body' ? rect.top + window.scrollY - dropdownHeight - marginTop : -130;
        left = left + 8;
      } else {
        top = props.dropdownPosition === 'body' ? topPosition : 0;
      }

      if (props.dropdownPosition === 'body') {
        setSelectPopupStyle({
          top: `${top}px`,
          left: `${left}px`,
          width: `${rect.width}px`,
          zIndex: '1055'
        });
      } else {
        setSelectPopupStyle({
          top: `${top}px`,
        });
      }
  
    }
  }

  const selectItem = (item: { id: string, value: string }) => {
    if (props.isMultiselect) {
      const newSelected = props.selectedMulti ? [...props.selectedMulti] : [];
      const aux = newSelected.map(item => item.id);

      if (aux.includes(item.id)) {
        const indexOf = aux.indexOf(item.id);
        newSelected.splice(indexOf, 1);
      } else {
        newSelected.push(item);
      }
      if (Object.hasOwn(props, 'setSelectedMulti')) {
        props.setSelectedMulti(newSelected);
        formatPopupPosition();
      }
      if (Object.hasOwn(props, 'setCustomSelected') && props.id) {
        props.setCustomSelected(props.id, newSelected);
      }
    } else {
      if (Object.hasOwn(props, 'setSelected')) {
        props.setSelected(item);
      }
      if (Object.hasOwn(props, 'setCustomSelected') && props.id) {
        props.setCustomSelected(props.id, item);
      }
      setIsOpened(false);
    }
  }

  const clearSelected = () => {
    if (props.isMultiselect) {
      if (Object.hasOwn(props, 'setSelectedMulti')) {
        props.setSelectedMulti([]);
      }
      if (Object.hasOwn(props, 'setCustomSelected') && props.id) {
        props.setCustomSelected(props.id, []);
      }
    } else {
      if (Object.hasOwn(props, 'setSelected')) {
        props.setSelected(null);
      }
      if (Object.hasOwn(props, 'setCustomSelected') && props.id) {
        props.setCustomSelected(props.id, null);
      }
    }

    if (Object.hasOwn(props, 'customClearSelected')) {
      props.customClearSelected();
    }
  }

  const renderSelected = (title?: boolean): JSX.Element => {
    try {
      if (title) {
        if (props.isMultiselect) {
          return props.selectedMulti?.map((item: { id: string, value: string }) => item.value).join(', ');
        } else {
          return props.selected?.value;
        }
      } else {
        if (props.isMultiselect) {
          return props.selectedMulti?.map((item: { id: string, value: string }, index) => {
            return <span key={`select-paginated-content-${props.id}-${index}`} className="select-paginated-content-selected-badge">{item.value}</span>
          });
        } else {
          if (props.selected?.icon) {
            return (
              <span className="select-paginated-content-item">
                { props.showIconSelected && props.iconSelectedPosition !== 'end' && props.selected.icon }
                <span>{props.selected.value}</span>
                { props.showIconSelected && props.iconSelectedPosition === 'end' && props.selected.icon }
              </span>
            );
          } else {
            return <span className="select-paginated-content-item">{props.selected?.value}</span>;
          }
        }
      }
    } catch (error) {
      console.log(props);
      console.error(error);
    }
  }

  const getSelectedOptionClass = (option: any): string => {
    if (props.isMultiselect) {
      if (props.selectedMulti?.map((item: { id: string, value: string }) => item?.id).includes(option)) {
        return 'select-paginated-option-selected';
      }
    } else {
      if (props.selected?.id === option) {
        return 'select-paginated-option-selected';
      }
    }

    return '';
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!props.loading) {
      const newSearchTerm = e.target.value;
      setSearchTerm(e.target.value);

      if (Object.hasOwn(props, 'setExternalSearchTerm')) {
        props.setExternalSearchTerm(newSearchTerm);
      }

      if (newSearchTerm) {
        if (searchTimeout) {
          clearTimeout(searchTimeout)
          setSearchTimeout(null);
        }
    
        const newSearchTimeout = setTimeout(() => {
          props.search(newSearchTerm);
          setIsFiltered(true);
        }, 1500);
    
        setSearchTimeout(newSearchTimeout);
      } else {
        clearTimeout(searchTimeout)
        setSearchTimeout(null);
        setIsFiltered(false);
        props.search('');
      }
    }
  }

  const handleEnterSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!props.loading) {
      if (event.key === 'Enter') {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
        props.search(searchTerm);
        setIsFiltered(true);
      }
    }
  }

  const handleSimpleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(e.target.value);

    if (newSearchTerm) {
      setFilteredOptions(props.options?.filter(item => new RegExp(newSearchTerm, 'gi').test(item.value)) || []);
    } else {
      setFilteredOptions(props.options || []);
    }
  }

  const handleClearSearch = () => {
    if (props.hasSimpleFilter) {
      setSearchTerm('');
      setIsFiltered(false);
      setFilteredOptions(props.options || []);
      if (Object.hasOwn(props, 'setExternalSearchTerm')) {
        props.setExternalSearchTerm('');
      }
    } else {
      setSearchTerm('');
      setIsFiltered(false);
      if (Object.hasOwn(props, 'search')) {
        props.search('');
      }
      if (Object.hasOwn(props, 'setExternalSearchTerm')) {
        props.setExternalSearchTerm('');
      }
    }
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }

  const handleActionClick = () => {
    if (Object.hasOwn(props, 'action')) {
      props.action(searchTerm || '');
      setIsOpened(false);
    }
  }

  return (
    <CustomSelect
      t={t}
      type={props.type}
      dropdownPosition={props.dropdownPosition || 'default'}
      isMultiselect={props.isMultiselect}
      selectRef={selectRef}
      popupRef={popupRef}
      inputSearchRef={inputSearchRef}
      selectUniqueId={selectUniqueId}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      options={props.hasSimpleFilter ? filteredOptions : props.options}
      hasFilter={props.hasFilter}
      hasSimpleFilter={props.hasSimpleFilter}
      hasAction={props.hasAction}
      selected={props.selected}
      selectedMulti={props.selectedMulti}
      selectItem={selectItem}
      hasSelected={hasSelected}
      renderSelected={renderSelected}
      clearSelected={clearSelected}
      placeholder={props.placeholder}
      placeholderFilter={props.placeholderFilter}
      tooltipAction={props.tooltipAction}
      getSelectedOptionClass={getSelectedOptionClass}
      fetchMore={props.fetchMore}
      hasMore={props.hasMore}
      loading={props.loading}
      searchTerm={searchTerm}
      isFiltered={isFiltered}
      handleSimpleFilter={handleSimpleFilter}
      handleSearchChange={handleSearchChange}
      handleEnterSearch={handleEnterSearch}
      handleClearSearch={handleClearSearch}
      handleActionClick={handleActionClick}
      disabled={props.disabled}
      required={props.required}
      selectPopupStyle={selectPopupStyle}
      showTooltipSelected={Object.hasOwn(props, 'showTooltipSelected') ? props.showTooltipSelected : true}
      tooltipSelectedPosition={Object.hasOwn(props, 'tooltipSelectedPosition') ? props.tooltipSelectedPosition : 'auto'}
      showTooltipOptions={props.showTooltipOptions}
      tooltipOptionsPosition={props.tooltipOptionsPosition}
    />
  );
}

export default CustomSelectController;