import { FC, useEffect, useState } from 'react';
import ModalMassActions from './index';
import { ModalMassActionsControllerInterface, RequestDeleteBatchTickets, RequestSolveBatchTickets } from './indexModel';
import { useTranslation } from 'react-i18next';
import emitter from '../../../../../core/shared/emitter';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { FetchTagsInterface, FormatDataSelectType, GetTagsSelectType } from '../../viewTicket/components/detailsComponent/indexModel';
import { I_TagPost } from '../../../homeConfigurations/homeTags/components/modalTag/indexModel';
import BackendConstants from '../../../../../core/constants/backend-constants';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 10;
const LIMIT_TICKET_SELECTION = BackendConstants.LIMIT_MANAGER_TICKETS;

const ModalMassActionsController: FC<ModalMassActionsControllerInterface> = (props) => {
  const { t } = useTranslation('ModalMassActions');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState<string[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [actionType, setActionType] = useState<'add-tag' | 'resolve' | 'delete' | 'remove-agents'>(null);

  // Tags
  const [tagsList, setTagsList] = useState<CustomSelectOptionType[]>([]);
  const [currentTagsListPage, setCurrentTagsListPage] = useState(1);
  const [hasMoreTagsList, setHasMoreTagsList] = useState(false);
  const [selectedTags, setSelectedTags] = useState<CustomSelectOptionType[]>([]);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-manage-tickets', data => {
      setData(data.ticket_ids);
    });

    return () => {
      emitter.off('open-modal-manage-tickets');
    }
  }, []);

  useEffect(() => {
    if (actionType === 'add-tag') {
      fetchTags(false, 1);
    }
  }, [actionType]);

  const selectActionType = (type: 'add-tag' | 'resolve' | 'delete' | 'remove-agents') => {
    if (data?.length <= LIMIT_TICKET_SELECTION) {
      setActionType(type);
    }
  }

  const isValid = (): boolean => {
    let valid = true;

    if (!actionType) {
      valid = false;
    }

    if (actionType === 'add-tag') {
      valid = selectedTags?.length > 0;
    }

    return valid;
  }

  const handleSubmit = () => {
    if (isValid()) {
      if (actionType === 'add-tag') {
        addTagsSelectedTickets();
      } else if (actionType === 'resolve') {
        resolveSelectedTickets();
      } else if (actionType === 'delete') {
        deleteSelectedTickets();
      } else if (actionType === 'remove-agents') {
        removeAssignedAgentsSelectedTickets();
      }
    }
  }

  const addTagsSelectedTickets = () => {
    const headers = UserService.getHeaders();

    const payload = {
      tickets_id: data,
      tags_id: selectedTags.map(tag => tag.id)
    }

    // PUT /ticket/tags
    // Response: { status: number, data: { code_cxpress: number, message: string }}

    AppRequesterConst.Put(
      '/ticket/tags', payload, { headers },
      () => {},
      (data: RequestSolveBatchTickets) => {
        dispatch(setShowAlertFeedback({ message: t("alert.successfully_submit"), visibility: true, signalIcon: true }))
        props.hide(true);
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error_submit"), visibility: true, signalIcon: false }))
      }, navigate, dispatch, setLoadingRequest
    );
  }

  const resolveSelectedTickets = () => {
    const headers = UserService.getHeaders();

    const payload = {
      ticketIds: data
    }

    // PUT /ticket/solve-batch-tickets
    // Response: { status: number, data: { code_cxpress: number, message: string, tickets: TicketInterface[] }} // os tickets vêm com a flag processing true
    // Evento socket: "tickets-processing"
    
    AppRequesterConst.Put(
      '/ticket/solve-batch-tickets', payload, { headers },
      () => {},
      (data: RequestSolveBatchTickets) => {
        dispatch(setShowAlertFeedback({ message: t("alert.successfully_submit_processing"), visibility: true, signalIcon: true }))
        props.hide(true);
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error_submit"), visibility: true, signalIcon: false }))
      }, navigate, dispatch, setLoadingRequest
    );
  }

  const deleteSelectedTickets = () => {
    const headers = UserService.getHeaders();

    const payload = {
      ids: data
    }

    const config = { headers, data: payload };

    AppRequesterConst.Delete(
      '/ticket', config,
      () => {},
      (data: RequestDeleteBatchTickets) => {
        dispatch(setShowAlertFeedback({ message: t("alert.successfully_submit_processing"), visibility: true, signalIcon: true }))
        props.hide(true);
      },
      (error: { response: { data: { message: {context: { key: string }}[]} }}) => {
        if (error.response.data.message[0]) {
          dispatch(setShowAlertFeedback({ message: t(`alert.apply_error_${error.response.data.message[0].context.key}`), visibility: true, signalIcon: false }))
        } else {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error_submit"), visibility: true, signalIcon: false }))
        }
      }, navigate, dispatch, setLoadingRequest
    );
  }

  const removeAssignedAgentsSelectedTickets = () => {
    const headers = UserService.getHeaders();

    const payload = {
      ticket_ids: data
    }

    AppRequesterConst.Put(
      '/ticket/remove-assignment', payload, { headers },
      () => {},
      () => {
        dispatch(setShowAlertFeedback({ message: t("alert.successfully_submit"), visibility: true, signalIcon: true }))
        props.hide(true);
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error_submit"), visibility: true, signalIcon: false }))
      }, navigate, dispatch, setLoadingRequest
    );
  }

  // Tags Requests
  
  const fetchTags = (isPagination: boolean, newPage: number, termSearch?: string) => {
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage, is_active: true, search: termSearch };

    if (!params.search) {
      delete params.search;
    }

    const headers = UserService.getHeaders();

    const config = { headers, params };

    setCurrentTagsListPage(newPage);

    AppRequesterConst.Get(
      `/tag`,
      config,
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data?.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTagsList(true);
          } else {
            setHasMoreTagsList(false);
          }

          if (isPagination) {
            const new_array = [...tagsList, ...formatDataSelect(response.data.tags, 'tags')];
            setTagsList(new_array);
          } else {
            setTagsList(formatDataSelect(response.data.tags, 'tags') || []);
          }
        } else {
          if (!isPagination) {
            setTagsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingTagsSelect, { }
    );
  }

  const fetchTagsPagination = async (page?: number) => {
    if (hasMoreTagsList || page?.toString().length > 0) {
      const newPage = page?.toString().length > 0 ? page : (currentTagsListPage + 1);
      setCurrentTagsListPage(newPage);

      fetchTags(page?.toString().length > 0 ? false : true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  const createTag = (name?: string) => {
    const headers  = UserService.getHeaders();
      
    const config = { headers };

    const payload = {
      name: name,
      active: true
    };

    emitter.emit('clear-all-search-custom-select');

    AppRequesterConst.Post(
      '/tag', payload, config,
      () => {},
      (response: I_TagPost) => {
        if (response.status === 201) {
          dispatch(setShowAlertFeedback({ message: t('alert.successfully_create_tag'), visibility: true, signalIcon: true }));

          fetchTagsPagination(1);
          setSelectedTags(prevState => {
            const newOption: CustomSelectOptionType = { id: response.data.tags[0].id, value: response.data.tags[0].name };
            if (prevState) {
              return [newOption, ...prevState];
            } else {
              return [newOption];
            }
          });
        }
      },
      (error: { response?: { status: number; data: { statusCode: number; code_cxpress: number; message: string; }} }) => {
        if (error.response?.data?.code_cxpress === 2307) {
          dispatch(setShowAlertFeedback({ message: t('alert.existent_tag'), visibility: true, signalIcon: false }));
        } else {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error_create_tag'), visibility: true, signalIcon: false }));
        }
      },
      navigate, dispatch, setIsLoadingTagsSelect
    );
  }

  const formatDataSelect = (data: any[], type: FormatDataSelectType): { id: string, value: string}[] => {
    const formatted = [];

    data.forEach(item => {
      if (item) {
        if (type === 'tags') {
          formatted.push({ id: item.id, value: item.name });
        }
      }
    });

    return formatted;
  }

  const getTagsSelect = (): GetTagsSelectType => {
    return {
      tagsList,
      selectedTags,
      setSelectedTags,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTagsList,
    };
  }

  const showOption = (type: 'add-tag' | 'resolve' | 'delete' | 'remove-agents'): boolean => {
    if (props.pageType === 'ticket-list') {
      return ['add-tag', 'resolve', 'delete', 'remove-agents'].includes(type);
    } else if (props.pageType === 'history-chatbot') {
      return ['add-tag'].includes(type);
    }
  }

  return (
    <ModalMassActions 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingRequest={loadingRequest}
      actionType={actionType}
      selectActionType={selectActionType}
      isValid={isValid}
      handleSubmit={handleSubmit}
      getTagsSelect={getTagsSelect}
      createTag={createTag}
      showOption={showOption}
    />
  );
};

export default ModalMassActionsController;
