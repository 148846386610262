import './styles.scss';
import { FC } from "react";
import { TableListInterface } from "./indexModel";
import { 
  IconAdjustments,
  IconArrowBackUp,
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconArrowsDiagonal2,
  IconArrowsDiagonalMinimize,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconDeselect,
  IconDotsVertical,
  IconEye,
  IconFileDownload,
  IconHelp,
  IconProgressAlert,
  IconSquare,
  IconSquareCheckFilled,
  IconSquareMinusFilled,
  IconSquareOff,
  IconTicket
} from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../../../../../components/loading2';
import FadeInOut from '../../../../../components/fadeInOut';
import BackendConstants from '../../../../../core/constants/backend-constants';
// import InfiniteScroll from 'react-infinite-scroll-component';

const LIMIT_TICKET_SELECTION = BackendConstants.LIMIT_MANAGER_TICKETS;

const TableListComponent: FC<TableListInterface> = (props) => {

  const classContent = props.compactList ? 'table-list-component-compacted' : '';
  const classHideList = props.showList ? '' : 'table-list-component-hide';
  const classEmpty = !props.data || props.data?.length === 0 ? 'table-list-component-content-empty' : '';
  const classHeaderHistoryChatbot = props.pageType === 'history-chatbot' ? 'table-header-history-chatbot' : '';
  const classListHistoryChatbot = props.pageType === 'history-chatbot' ? 'table-list-items-history-chatbot' : '';
  const headerWithoutCheckClass = props.hasItemCheckSelection ? '' : 'table-header-without-check';
  const itemWithoutCheckClass = props.hasItemCheckSelection ? '' : 'table-list-item-without-check';

  return (
    <div className={`table-list-component ${classContent} ${classHideList}`.trim()} id="table-list-items" ref={props.tableListRef}>
      {
        !props.compactList &&
        <div id='table-list-component-content' className={`table-list-component-content ${classEmpty}`}
          // style={{ height: `calc(100vh - ${props.offsetHeight}px - 44px)` }}
          >
          { props.data?.length > 0 &&
            <div className={props.util_hasVerticalScrollbar ? `table-header table-scroll ${classHeaderHistoryChatbot} ${headerWithoutCheckClass}` : `table-header ${classHeaderHistoryChatbot} ${headerWithoutCheckClass}`}>
              { props.hasItemCheckSelection && (!props.hasSomeSelectedCheck() || props.isAllSelectedItems()) && !props.isAllSelectedItems() && !props.isItemCheckSelectionDisabled &&
                <span className="table-header-item check-btn" onClick={() => props.selectAllItems()}>
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('select_all_tickets')}</Tooltip>}>
                    <IconSquare className='icon-check-btn' color='#bcbcbc' strokeWidth={1}/>
                  </OverlayTrigger>
                </span>
              }
              {
                props.hasItemCheckSelection && (!props.hasSomeSelectedCheck() || props.isAllSelectedItems()) && props.isAllSelectedItems() && 
                <span className="table-header-item check-btn" onClick={() => props.selectAllItems()}>
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('unselect_all_tickets')}</Tooltip>}>
                    <IconSquareCheckFilled className='icon-check-btn icon-check-filled' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                  </OverlayTrigger>
                </span>
              }
              { props.hasItemCheckSelection && !props.isAllSelectedItems() && props.hasSomeSelectedCheck() &&
                <span className="table-header-item check-btn" onClick={() => props.clearPageSelected()}>
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('unselect_all_tickets')}</Tooltip>}>
                    <IconSquareMinusFilled className='icon-check-btn' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                  </OverlayTrigger>
                </span>
              }
              { props.hasItemCheckSelection && (!props.hasSomeSelectedCheck() || props.isAllSelectedItems()) && !props.isAllSelectedItems() && props.isItemCheckSelectionDisabled &&
                <span className="table-header-item check-btn">
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('select_disabled_limit', { limit: LIMIT_TICKET_SELECTION})}</Tooltip>}>
                    <IconSquareOff className='icon-check-btn' color='#bcbcbc' strokeWidth={1}/>
                  </OverlayTrigger>
                </span>
              }
              <span className="table-header-item table-header-item-who-is-viewing"></span>
              {props.headers()?.map((header, index) => {
                let headerItemCss = 'table-header-item';
                const customStyle = {
                  width: `calc(100% / ${(props.headers()?.length + 1) || 1})`,
                }

                const showColumnSelectorClass = props.showColumnSelector ? 'show-column-selector' : '';

                if (header.width !== 'auto') {
                  customStyle['width'] = header.width;
                  customStyle['minWidth'] = header.width;
                  customStyle['maxWidth'] = header.width;
                }

                if (header.maxWidth) {
                  customStyle['maxWidth'] = header.maxWidth;
                }

                if (header.field === 'action') {
                  customStyle['width'] = '100%';
                  headerItemCss += ' header-item-action';
                }

                if (header.hasOrderBy) {
                  if (header.hasChangeColumn) {
                    return (
                      <span key={index} className={`${headerItemCss} header-with-select ${showColumnSelectorClass}`} style={customStyle}>
                        <span className="column-title btn-column-selector" onClick={() => props.setShowColumnSelector(!props.showColumnSelector)}>
                          <span 
                            className="column-title-value" 
                            onMouseEnter={() => props.setHoverColumnSelector(true)} 
                            onMouseLeave={() => props.setHoverColumnSelector(false)}
                            ref={props.columnBtnSelectRef}
                          >
                            {header.title}
                            { (props.hoverColumnSelector || props.showColumnSelector) && <IconChevronDown className={`icon-header-select ${props.showColumnSelector ? 'icon-header-select-show' : ''}`} strokeWidth={1} size={23} /> }
                          </span>
                          <FadeInOut duration={200} show={props.showColumnSelector} customRef={props.columnSelectorRef}>
                            <div className='popover-table-list-column-type'>
                              <div className='popover-table-list-column-type-body'>
                                { header.field === 'home_ticket_update_at' && 
                                  <span className='popover-item' onClick={() => header.sortByFN('home_ticket_update_at', 'updated_level2_at', header.orderBy)}>Data de criação</span> 
                                }
                                { header.field === 'updated_level2_at' && ['ticket-list', 'consumer-tickets', 'agent-tickets', 'messages-trigger-tickets', 'tag-tickets', 'sector-tickets'].includes(props.pageType) && 
                                  <span className='popover-item' onClick={() => header.sortByFN('updated_level2_at', 'home_ticket_update_at', header.orderBy)}>Data de atualização</span> 
                                }
                                { header.field === 'updated_level2_at' && props.pageType === 'history-general' && 
                                  <span className='popover-item' onClick={() => header.sortByFN('updated_level2_at', 'finished_at', header.orderBy)}>Data de finalização</span> 
                                }
                                { header.field === 'finished_at' && 
                                  <span className='popover-item' onClick={() => header.sortByFN('finished_at', 'updated_level2_at', header.orderBy)}>Data de criação</span> 
                                }
                              </div>
                            </div>
                          </FadeInOut>
                        </span>
                        {
                          !props.hoverColumnSelector && !props.showColumnSelector && header.orderBy === 'asc' && 
                          <OverlayTrigger key={`header-${header.field}-asc`} placement='top' overlay={<Tooltip>{props.t('order_by_asc')}</Tooltip>}>
                            <IconArrowNarrowUp className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'desc')} />
                          </OverlayTrigger>
                        }
                        {
                          !props.hoverColumnSelector && !props.showColumnSelector && header.orderBy === 'desc' && 
                          <OverlayTrigger key={`header-${header.field}-desc`} placement='top' overlay={<Tooltip>{props.t('order_by_desc')}</Tooltip>}>
                            <IconArrowNarrowDown className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'asc') } />
                          </OverlayTrigger>
                        }
                      </span>
                    );
                  } else {
                    return (
                      <span key={index} className={`${headerItemCss}`} style={customStyle}>
                        <span className="column-title">
                          <span>{header.title}</span>
                        </span>
                        {
                          !props.hoverColumnSelector && !props.showColumnSelector && header.orderBy === 'asc' && 
                          <OverlayTrigger key={`header-${header.field}-asc`} placement='top' overlay={<Tooltip>{props.t('order_by_asc')}</Tooltip>}>
                            <IconArrowNarrowUp className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'desc')} />
                          </OverlayTrigger>
                        }
                        {
                          !props.hoverColumnSelector && !props.showColumnSelector && header.orderBy === 'desc' && 
                          <OverlayTrigger key={`header-${header.field}-desc`} placement='top' overlay={<Tooltip>{props.t('order_by_desc')}</Tooltip>}>
                            <IconArrowNarrowDown className='icon-header-sort' strokeWidth={2} size={23} onClick={() => header.orderByFn(header.field, 'asc') } />
                          </OverlayTrigger>
                        }
                      </span>
                    );
                  }
                } else {
                  return <span key={index} className={headerItemCss} style={customStyle}>{header.title}</span>
                }

              })}
            </div>
          }
          { props.isLoading && <Loading blur={true} /> }
          {
            props.data && props.data?.length > 0 &&
            <div id="table-list-items" className={props.hasSomeSelected() ? `table-list-items list-action-selected ${classListHistoryChatbot}` : `table-list-items ${classListHistoryChatbot}`}>
              {/* <InfiniteScroll
                dataLength={props.data.length}
                next={props.fetchMore}
                hasMore={true}
                loader={<></>}
                scrollableTarget="table-list-component-content"
                scrollThreshold="100px"
              > */}
                {props.data.map(item => {
                  let tableListItemCss = 'table-list-item';

                  if (item.bold) {
                    tableListItemCss += ' table-list-item-updated';
                  }

                  if (props.isSelectedItem(item.id)) {
                    tableListItemCss += ' table-list-item-selected';
                  }

                  if (item.id === props.selectedItemId) {
                    tableListItemCss += ' table-list-item-opened';
                  }

                  if (item.who_is_viewing) {
                    tableListItemCss += ' table-list-some-viewing';
                  }

                  if (item.processing) {
                    tableListItemCss += ' table-list-item-processing';
                  }

                  if (props.getIsItemDisabled(item, false)) {
                    tableListItemCss += ' table-list-item-disabled';
                  }

                  return (
                    <div className={tableListItemCss} key={item.id} onClick={(e) => props.openTicket(item, e)}>
                      <div className="table-list-item-align">
                        { props.hasItemCheckSelection && !item.processing && !item.who_is_viewing && !props.isSelectedItem(item.id) && !props.isItemCheckSelectionDisabled &&
                          <span className="check-btn" onClick={(e) => props.selectItem(item, e)}>
                            <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{props.t('select_one_ticket')}</Tooltip>}>
                              <IconSquare className='icon-check-btn' color='#bcbcbc' strokeWidth={1} />
                            </OverlayTrigger>
                          </span>
                        }
                        { props.hasItemCheckSelection && !item.processing && !item.who_is_viewing && props.isSelectedItem(item.id) &&
                          <span className="check-btn" onClick={(e) => props.selectItem(item, e)}>
                            <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{props.t('unselect_one_ticket')}</Tooltip>}>
                              <IconSquareCheckFilled className='icon-check-btn icon-check-filled' color='#4A69BD' strokeWidth={1} style={{ color: '#4A69BD'}} />
                            </OverlayTrigger>
                          </span>
                        }
                        {
                          props.hasItemCheckSelection && (item.processing || item.who_is_viewing || (!props.isSelectedItem(item.id) && props.isItemCheckSelectionDisabled)) &&
                          <span className="check-btn" onClick={(e) => e.stopPropagation()}>
                            { (item.processing || item.who_is_viewing) &&
                              <OverlayTrigger placement='top' overlay={<Tooltip>{props.t(item.processing ? 'select_disabled_processing' : 'select_disabled_viewing')}</Tooltip>}>
                                <IconSquareOff className='icon-check-btn' color='#979797' strokeWidth={1} style={{ color: '#979797'}} />
                              </OverlayTrigger>
                            }
                            { !item.processing && !item.who_is_viewing && (!props.isSelectedItem(item.id) && props.isItemCheckSelectionDisabled) &&
                              <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('select_disabled_limit', { limit: LIMIT_TICKET_SELECTION})}</Tooltip>}>
                                <IconSquareOff className='icon-check-btn' color='#979797' strokeWidth={1} style={{ color: '#979797'}} />
                              </OverlayTrigger>
                            }
                          </span>
                        }
                        <span className={`table-list-item-column table-list-item-who-is-viewing ${itemWithoutCheckClass}`}>
                          {!item.processing && props.hasOptionalAction && item.who_is_viewing && 
                            <OverlayTrigger 
                              container={props.tableListRef} 
                              placement='right' 
                              overlay={<Tooltip>{props.t(props.currentLoggedUserID === item.who_is_viewing_user_id ? 'ticket_viewing_you' : 'ticket_viewing', { agent_name: item.who_is_viewing })}</Tooltip>}
                            >
                              <IconEye />
                            </OverlayTrigger>
                          }
                          {
                            item.processing &&
                            <OverlayTrigger container={props.tableListRef} placement='right' overlay={<Tooltip>{props.t('ticket_processing')}</Tooltip>}>
                              <IconProgressAlert />
                            </OverlayTrigger>
                          }
                        </span>
                        {props.headers()?.map(header => {
                          let bodyItemCss = 'table-list-item-column';
                          const customStyle = {
                            width: `calc(100% / ${(props.headers()?.length + 1) || 1})`,
                            marginRight: '10px'
                          }
                
                          if (header.width !== 'auto') {
                            customStyle['width'] = header.width;
                            customStyle['minWidth'] = header.width;
                            customStyle['maxWidth'] = header.width;
                          }
    
                          if (header.maxWidth) {
                            customStyle['maxWidth'] = header.width;
                          }
    
                          if (header.field === 'action') {
                            customStyle['width'] = '100%';
                            customStyle['marginRight'] = '0';
                            customStyle['marginLeft'] = '10px';
                            bodyItemCss += ' table-list-item-action';
                          }
    
                          const cell_item = props.util_formatValue(item[header.field], header.field);
    
                          if (header.field === 'status_id') {
                            return (
                              <div key={header.field} className={bodyItemCss} style={{...customStyle }}> 
                                <OverlayTrigger container={props.tableListRef} placement='left' overlay={<Tooltip>{props.util_getStatusIcon(cell_item.value).tooltip}</Tooltip>}>
                                  <span className='badge-status' style={{ backgroundColor: props.util_getStatusIcon(cell_item.value).color }}>
                                    <span>{props.util_getStatusIcon(cell_item.value).symbol}</span>
                                  </span>
                                </OverlayTrigger>
                              </div>
                            );
                          } else if (header.field === 'action') {
                            return (
                              <div key={header.field} className={bodyItemCss} style={{...customStyle }}>
                                <span className="action">
                                  { !item.processing &&
                                    <OverlayTrigger 
                                      trigger={['click']} 
                                      placement='left' 
                                      overlay={props.popoverItemAction(item)} 
                                      rootClose={true}
                                      show={props.openedPopoverId === item.id}
                                      onToggle={() => props.setOpenedPopoverId(null)}
                                    >
                                      <IconDotsVertical color='#707070' strokeWidth={2} onClick={(e) => props.handlePopoverMenuAction(e, item.id)} />
                                    </OverlayTrigger>
                                  }
                                </span>
                              </div>
                            );
                          } else {
                            return (
                              <div key={header.field} className={bodyItemCss} style={customStyle}>
                                { header.hasTooltip && 
                                  <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip>{cell_item.value}</Tooltip>}>
                                    <p className='table-list-item-value'>
                                      {cell_item.icon}
                                      {cell_item.value}
                                    </p>
                                  </OverlayTrigger>
                                }
                                { !header.hasTooltip &&
                                  <p className='table-list-item-value'>
                                    {cell_item.icon}
                                    {cell_item.value}
                                  </p>
                                }
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              {/* </InfiniteScroll> */}
            </div>
          }
          {
            (!props.data || props.data?.length === 0) &&
            <div className={`table-list-items table-list-empty ${props.errorLoading ? 'table-list-error-loading' : ''}`}>
              <IconTicket className='tabler-icon-ticket' />
              { !props.isLoading && !props.errorLoading && <span className="table-list-info">{props.t('no_tickets')}</span>}
              { !props.isLoading && props.errorLoading && 
                <span className="table-list-info">{props.t('error_loading_tickets')}</span>
              }
              { props.errorLoading &&
                <span className="btn-reload-tickets" onClick={() => props.reloadTickets()}>Recarregar</span>
              }
              { props.isLoading && <span className="table-list-info">{props.t('loading_tickets')}</span>}
            </div>
          }
        </div>
      }
      { props.compactList && 
        <div className={`table-compact-component-content ${classHideList}`}>
          {/* <div className="table-compact-header" ref={props.tableCompactHeaderRef} style={{ height: props.showList ? '100%' : `calc(100vh - ${props.headerHeight}px - 12px)` }}> */}
          <div className="table-compact-header" ref={props.tableCompactHeaderRef}>
            <div className="table-compact-header-info">
              {
                props.showList &&
                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>{props.t('back')}</Tooltip>}>
                  <span className="table-compact-list-back" onClick={() => props.backToList()}>
                    <IconArrowBackUp />
                  </span>
                </OverlayTrigger>
              }
              <div className="table-compact-header-actions">
                {
                  props.showList &&
                  <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip'>{props.t('hide_list')}</Tooltip>}>
                    <span className="table-compact-list-back" onClick={() => props.setShowList(false)}>
                      <IconArrowsDiagonalMinimize />
                    </span>
                  </OverlayTrigger>
                }
                {
                  !props.showList &&
                  <OverlayTrigger placement='auto' overlay={<Tooltip id='tooltip'>{props.t('show_list')}</Tooltip>}>
                    <span className="table-compact-list-back" onClick={() => props.setShowList(true)}>
                      <IconArrowsDiagonal2 />
                    </span>
                  </OverlayTrigger>
                }
              </div>
            </div>
          </div>
          { props.isLoading && <Loading blur={true} /> }
          { 
            props.showList &&
            // <div id="table-compact-list-items" className="table-compact-list-items" style={{ height: `calc(100vh - ${props.getCompactHeaderOffsetHeight()}px)` }}>
            <div id="table-compact-list-items" className="table-compact-list-items" style={{ height: `calc(100vh - ${props.getComponentHeighDiff()}px)` }}>
              {/* <InfiniteScroll
                dataLength={props.data.length}
                next={props.fetchMore}
                hasMore={true}
                loader={null}
                scrollableTarget="table-compact-list-items"
                scrollThreshold="100px"
              > */}
                { props.data?.length > 0 && props.data?.map(item => {
                  const ticket_reference = props?.util_formatValue(item['ticket_reference_id'], 'ticket_reference_id');
                  const ticket_status = props?.util_formatValue(item['status_id'], 'status_id');
                  const ticket_channel = props?.util_formatValue(item['channel_id'], 'channel_id');
                  const last_message = props?.util_formatValue(item['last_message'], 'last_message');
                  const ticket_requester = props?.util_formatValue(item['requester_user'], 'requester_user');
                  const ticket_updated_level2_at = props?.util_formatValue(item?.[props?.getSortByList(props?.pageType)], 'updated_level2_at', 'long');
                  const ticket_date_relative = props?.getDateSystemMsg(item?.[props?.getSortByList(props?.pageType)]);
                  
                  let tableCompactItemCss = 'table-compact-list-item';

                  if (item.id === props.selectedItemId) {
                    tableCompactItemCss += ' table-compact-list-item-opened';
                  }

                  if (item.bold) {
                    tableCompactItemCss += ' table-compact-list-item-updated';
                  }

                  if (item.who_is_viewing) {
                    tableCompactItemCss += ' table-compact-list-item-some-viewing'
                  }

                  if (item.processing) {
                    tableCompactItemCss += ' table-compact-list-item-processing'
                  }
                  
                  return (
                    <div key={item.id} className={tableCompactItemCss} onClick={(e) => props.openTicket(item, e)}>
                      <div className="compact-item-header">
                        <div className="compact-item-info">
                          <span className="ticket-reference-id">{ticket_reference.value}</span>
                          <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip id='tooltip'>{ticket_requester.value}</Tooltip>}>
                            <p className="ticket-requester">{ticket_requester.value}</p>
                          </OverlayTrigger>
                        </div>
                        <div className="compact-item-status">
                          { !item.processing && props.hasOptionalAction && item.who_is_viewing &&
                            <span className="icon-activity">
                              <OverlayTrigger 
                                container={props.tableListRef} 
                                placement='top' 
                                overlay={<Tooltip>{props.t(props.currentLoggedUserID === item.who_is_viewing_user_id ? 'ticket_viewing_you' : 'ticket_viewing', { agent_name: item.who_is_viewing })}</Tooltip>}
                              >
                                <IconEye />
                              </OverlayTrigger>
                            </span>
                          }
                          { item.processing &&
                            <span className="icon-activity">
                              <OverlayTrigger container={props.tableListRef} placement='right' overlay={<Tooltip>{props.t('ticket_processing')}</Tooltip>}>
                                <IconProgressAlert />
                              </OverlayTrigger>
                            </span>
                          }
                          <span className="icon-channel">
                            { !!ticket_channel.icon &&
                              <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip id='tooltip'>{ticket_channel.value}</Tooltip>}>
                                { ticket_channel.icon }
                              </OverlayTrigger>
                            }
                            { !ticket_channel?.icon &&
                              <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip id='tooltip'>{props.t('channels_names.na')}</Tooltip>}>
                                <IconHelp stroke={1} color='#909090' size={26} />
                              </OverlayTrigger>
                            }
                          </span>
                          <span className='badge-status' style={{ backgroundColor: props.util_getStatusIcon(ticket_status.value).color }}>
                            <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip id='tooltip'>{props.util_getStatusIcon(ticket_status.value).tooltip}</Tooltip>}>
                              <span>{props.util_getStatusIcon(ticket_status.value).symbol}</span>
                            </OverlayTrigger>
                          </span>
                        </div>
                      </div>
                      <div className="compact-item-preview">
                        { item.last_message &&
                          <OverlayTrigger container={props.tableListRef} placement='top' overlay={<Tooltip id='tooltip'>{last_message.value}</Tooltip>}>
                            <p className="ticket-preview-message">{last_message.value}</p>
                          </OverlayTrigger>
                        }
                        {
                          !item.last_message &&
                          <p className="ticket-preview-no-message">{props.t('no_messages')}</p>
                        }
                        <div className="additional-content">
                          <span className="updated-date" title={ticket_updated_level2_at.value}>{ticket_date_relative}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* </InfiniteScroll> */}
                {
                  (!props.data || props.data?.length === 0) &&
                  <div className={`table-compact-list-empty ${props.errorLoading ? 'table-list-error-loading' : ''}`}>
                    <IconTicket className='tabler-icon-ticket' />
                    { !props.isLoading && !props.errorLoading && <span className="table-list-info">{props.t('no_tickets')}</span>}
                    { !props.isLoading && props.errorLoading && 
                      <span className="table-compact-list-info">{props.t('error_loading_tickets')}</span>
                    }
                    { props.errorLoading &&
                      <span className="btn-reload-tickets" onClick={() => props.reloadTickets()}>Recarregar</span>
                    }
                    { props.isLoading && <span className="table-compact-list-info">{props.t('loading_tickets')}</span>}
                  </div>
                }
            </div>
          }
        </div>
      }
      {
        props.showList && (props.data?.length > 0 || props.paginationDetails?.pages?.length > 0) &&
        <div className="table-list-footer">
          <div className="table-list-footer-left">
            { !props.compactList && props.data?.length > 0 &&
            <div className="table-list-actions">
              { !props.hasSomeSelected() && props.hasExportAll && ['ticket-list', 'history-general'].includes(props.pageType) &&
                <button className='table-list-action' onClick={props.exportAll}>
                  {props.t('export')}
                  <IconFileDownload />
                </button>
              }
              { props.hasSomeSelected() && props.hasExportSelected &&
                <button className='table-list-action' onClick={props.exportSelected}>
                  {props.t('export')} ({props.selectedItemsLength})
                  <IconAdjustments />
                </button>
              }
              { props.hasSomeSelected() && props.hasManageSelected &&
                <button className='table-list-action' onClick={props.manageSelected}>
                  {props.t('manage_tickets')} ({props.selectedItemsLength})
                  <IconAdjustments />
                </button>
              }
              { props.hasSomeSelected() && 
                <OverlayTrigger overlay={<Tooltip>{props.t('clear_selection')}</Tooltip>}>
                  <button className='table-list-action table-list-action-clear' onClick={props.clearSelected}>
                    <IconDeselect />
                  </button>
                </OverlayTrigger>
              }
            </div>
            }
          </div>
          <div className="table-list-footer-right">
            { (props.data?.length > 0 || props.paginationDetails?.pages.length > 0) &&
              <div className="table-list-footer-pagination">
                { !props.paginationDetails && <span className='table-pagination-info'>...</span> }
                { props.paginationDetails && 
                  <>
                  <span className='table-pagination-info'>
                    <span className="table-pagination-interval">{props.util_formatNumberValue(props.paginationDetails.from)} - {props.util_formatNumberValue(props.paginationDetails.to)}</span>
                    <span className="table-pagination-separator">{props.t('pagination_of')}</span>
                    <span className="table-pagination-total-docs">{props.util_formatNumberValue(props.paginationDetails.total)}</span>
                  </span>
                  <span className="table-pagination-action" ref={props.paginationRef}>
                    { props.paginationDetails?.hasPrev &&  
                      <IconChevronLeft className="table-pagination-prev" onClick={() => props.handleClickPage(props.paginationDetails.prevPage)} /> 
                    }
                    { !props.paginationDetails?.hasPrev &&
                      <IconChevronLeft className="table-pagination-disabled" /> 
                    }
                    <div className="table-pagination-selected">
                      <OverlayTrigger key='table-pagination-selected' placement={props.showPopupPages ? 'left' : 'top'} overlay={<Tooltip>{props.t('pagination_select')}</Tooltip>}>
                        <span className="table-pagination-value" onClick={() => props.setShowPopupPages(!props.showPopupPages)}>
                          {props.paginationDetails.currentPage}
                        </span>
                      </OverlayTrigger>
                      {
                        props.showPopupPages &&
                        <div className="table-pagination-popup-pages">
                          {props.paginationDetails.pages.map(page => {
                            const selectedClass = page === props.paginationDetails.currentPage ? 'table-pagination-popup-item-selected' : '';
                            return (
                              <span key={page} id={`table-tickets-pagination-${page}`} className={`table-pagination-popup-item ${selectedClass}`} onClick={() => props.handleClickPage(page)}>
                                {page}
                              </span>
                            );
                          })}
                        </div>
                      }
                    </div>
                    { props.paginationDetails?.hasNext &&
                      <IconChevronRight className="table-pagination-next" onClick={() => props.handleClickPage(props.paginationDetails.nextPage)} />
                    }
                    { !props.paginationDetails?.hasNext &&
                      <IconChevronRight className="table-pagination-disabled" />
                    }
                  </span>
                  </>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
}

export default TableListComponent;