import './styles.scss';
import { FC } from "react";
import { ViewTicketInterface } from "./indexModel";
import { IconArrowBackUp, IconCheckupList, IconChevronDown, IconDotsVertical, IconEye, IconHelp, IconHistory } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import Loading from '../../../../components/loading2';
import MessagesComponentController from './components/messagesComponent/indexController';
import DetailsControllerComponent from './components/detailsComponent/indexController';
import ChatEditorComponentController from './components/chatEditorComponent/indexController';
import ModalTicketLogsController from './components/modalTicketLogs/indexController';
import ModalListTasksController from '../../homeTasks2/components/modalListTasks/indexController';
import ModalDeleteTicketController from '../components/modalDeleteTicket/indexController';
import ModalSendTicketCopyController from '../components/modalSendTicketCopy/indexController';
import ModalReopenTicketController from '../components/modalReopenTicket/indexController';
import ModalRecoveryDeletedTicketController from '../components/modalRecoveryDeletedTicket/indexController';
import { Trans } from 'react-i18next';

const ViewTicket: FC<ViewTicketInterface> = (props) => {

  const status = props.util_getStatusIcon(props.ticket?.status_id || TicketStatus.Open);
  const styleHideDetailsClass = props.showTicketDetails ? '' : ' ticket-details-hide';
  const channel = props.util_getChannel(props.ticket?.channel_id);
  const deletedTicketClass = props.isDeletedTicket ? 'ticket-chat-deleted' : '';
  const deletedConsumerClass = props.getConsumerInfo().isDeleted ? 'ticket-chat-header-requester-deleted' : '';

  return (
    <div className="view-ticket-content">
      { props.showModalTicketLogs && <ModalTicketLogsController show={props.showModalTicketLogs} hide={props.closeTicketLogsModal} /> }
      { props.showModalListTasks && <ModalListTasksController show={props.showModalListTasks} hide={props.closeModalListTasks} /> }
      { props.showModalDeleteTicket && <ModalDeleteTicketController show={props.showModalDeleteTicket} hide={props.closeModalDeleteTicket} /> }
      { props.showModalReopenTicket && <ModalReopenTicketController show={props.showModalReopenTicket} hide={props.closeModalReopenTicket} /> }
      { props.showModalRecoveryDeletedTicket && <ModalRecoveryDeletedTicketController show={props.showModalRecoveryDeletedTicket} hide={props.closeModalRecoveryDeletedTicket} /> }
      { props.showModalSendTicketCopy && <ModalSendTicketCopyController show={props.showModalSendTicketCopy} hide={props.closeModalSendTicketCopy} /> }
      
      { props.getLoadings() && <Loading blur={true} /> }
      <div className={`ticket-chat ${styleHideDetailsClass}`}>
        <div className="ticket-chat-header">
          <div className="ticket-chat-header-info">
            {
              !props.showList &&
              <span className='ticket-chat-header-action'>
                <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('back')}</Tooltip>}>
                  <span className="table-compact-list-back" onClick={() => props.backToList()}>
                    <IconArrowBackUp />
                  </span>
                </OverlayTrigger>
              </span>
            }
            <span className="ticket-chat-header-reference-id">#{props.ticket?.ticket_reference_id || '...'}</span>
            <OverlayTrigger placement='top' overlay={<Tooltip>{props.t(!!deletedConsumerClass ? 'deleted_consumer' : 'view_consumer')}</Tooltip>}>
              <span className={`ticket-chat-header-requester ${deletedConsumerClass}`} onClick={() => props.openConsumerModal()}>{props.getConsumerInfo().value}</span>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>{channel.title}</Tooltip>}>
              <span className="ticket-chat-header-channel">{channel.icon || <IconHelp stroke={1} color='#707070' size={26} />}</span>
            </OverlayTrigger>
            <span className="ticket-chat-header-badge-status" style={{ backgroundColor: status?.color}}>
              {status?.value}
            </span>
          </div>
          <div className="ticket-chat-header-actions">
            <span className='ticket-chat-header-action' onClick={() => props.setShowModalTicketLogs(true)}>
              <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('ticket_history')}</Tooltip>}>
                <IconHistory />
              </OverlayTrigger>
            </span>
            <span className='ticket-chat-header-action'>
              <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('ticket_tasks')}</Tooltip>}>
                <IconCheckupList onClick={() => props.openModalListTasks()} />
              </OverlayTrigger>
            </span>
            <OverlayTrigger 
              trigger={['click']} 
              placement='left' 
              overlay={props.popoverItemAction()} 
              rootClose={true}
              show={props.showMenuAction}
            >
              <span className='ticket-chat-header-action' onClick={() => props.handleShowTicketMenuAction()}>
                <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('actions')}</Tooltip>}>
                  <IconDotsVertical />
                </OverlayTrigger>
              </span>
            </OverlayTrigger>
          </div>
        </div>
        {/* <div className="ticket-chat-container" style={{ height: `calc(100% - 40px)` }} ref={props.ticketChatContainerRef}> */}
        <div className="ticket-chat-container" ref={props.ticketChatContainerRef} style={{ height: `calc(100vh - ${props.getHeaderHeight()}px)`}}>
          <MessagesComponentController 
            // ticketMessages={props.ticketMessages}
            transitionStyleClass={props.isDragging ? '' : 'ticket-chat-transition'}
            elementRef={props.chatMessagesRef}
            hasMoreMessage={props.hasMoreMessage}
            fetchMore={props.fetchTicketMessagesPagination}
            dynamicStyle={{ 
              minHeight: `200px`,
              maxHeight: `calc(100% - ${props.ticketChatEditorContainerHeight}px)`,
              height: `calc(100% - ${props.ticketChatEditorContainerHeight}px)`,
            }}
            goToPageAndElement={props.goToPageAndElement}
            ticketId={props?.ticket?.id}
          />
          <div className={props.isDragging ? 'ticket-chat-editor-container' : 'ticket-chat-editor-container ticket-chat-transition'} ref={props.ticketChatEditorContainerRef} 
            style={{ height: `${props.ticketChatEditorContainerHeight}px`, zIndex: props.getLoadings() ? '-1' : 'unset' }}>
            {
              !props.onlyRead &&
              <>
              <div className="ticket-chat-divider-resize ticket-chat-divider-cursor" onMouseDown={props.handleDividerMouseDown} onDoubleClick={props.handleDividerDoubleClick}>
                <OverlayTrigger placement='top' overlay={<Tooltip>{props.showChat ? 'Ocultar editor' : 'Mostrar editor'}</Tooltip>}>
                  <span className="hide-chat-btn" onClick={() => props.showHideChat(!props.showChat)}>
                    <IconChevronDown size={20} className={props.showChat ? '' : 'icon-open-chat'} />
                  </span>
                </OverlayTrigger>
              </div>
              <div className="ticket-chat-divider-resize ticket-chat-divider-cursor divider-no-resize" onDoubleClick={props.handleDividerDoubleClick}>
                <OverlayTrigger placement='top' overlay={<Tooltip>{props.showChat ? 'Ocultar editor' : 'Mostrar editor'}</Tooltip>}>
                  <span className="hide-chat-btn" onClick={() => props.showHideChat(!props.showChat)}>
                    <IconChevronDown size={20} className={props.showChat ? '' : 'icon-open-chat'} />
                  </span>
                </OverlayTrigger>
              </div>
              { props.showChat &&
                <div className="ticket-chat-editor">
                  <ChatEditorComponentController 
                    type='view-ticket'
                    editorState={props.editorState} 
                    setEditorState={props.setEditorState} 
                    sendMessage={props.sendMessage}
                    files={props.files}
                    setFiles={props.setFiles}
                    hasFileUploader={props.hasFileUploader}
                    setHasFileUploader={props.setHasFileUploader}
                    setTicketChatEditorContainerHeight={props.setTicketChatEditorContainerHeight}
                    audioRecordedFiles={props.audioRecordedFiles}
                    setAudioRecordedFiles={props.setAudioRecordedFiles}
                    currentAudioInfo={props.currentAudioInfo}
                    setCurrentAudioInfo={props.setCurrentAudioInfo}
                    statusChatEditor={props.statusChatEditor()}
                    hiddenToolbarItems={props.hiddenToolbar}
                    isLoadingViewTicket={props.getLoadings()}
                  />
                </div>
              }
              { !props.showChat &&
                <div className="ticket-chat-start" onClick={() => props.showHideChat(!props.showChat)}>
                  <span>{props.t('write_response')}</span>
                </div>
              }
              </> 
            }
            {
              props.onlyRead && props.ticket?.who_is_viewing && !props.isBlockedTicket(props.ticket, true) && !props.isDeletedTicket &&
              <div className="ticket-chat-blocked">
                <span>
                  <Trans 
                    i18nKey='ViewTicket:ticket_viewing'
                    values={{ agent_name: props.ticket?.who_is_viewing }}
                    components={[ <span /> ]} // Um elemento para cada valor em values
                  />
                </span>
              </div>
            }
            {
              props.onlyRead && props.isBlockedTicket(props.ticket, true) &&
              <div className={`ticket-chat-blocked ${deletedTicketClass}`}>
                { !props.isDeletedTicket &&
                  <span>{props.t('status')} <span className="ticket-chat-badge-status">{status.value}</span></span>
                }
                { props.isDeletedTicket &&
                  <span>{props.t('deleted_1')} <span className="ticket-chat-badge-status">{props.t('deleted_2')}</span></span>
                }
                {
                  props.ticket?.who_is_viewing &&
                  <OverlayTrigger
                    overlay={<Tooltip>{props.t(props.currentLoggedUserID === props.ticket?.who_is_viewing_user_id ? 'ticket_viewing_you' : 'ticket_viewing_tooltip', { agent_name: props.ticket?.who_is_viewing })}</Tooltip>}
                  >
                    <IconEye />
                  </OverlayTrigger>
                }
              </div>
            }
          </div>
        </div>
      </div>
      <DetailsControllerComponent
        showTicketDetails={props.showTicketDetails}
        setShowTicketDetails={props.setShowTicketDetails}
        pageType={props.pageType}
      />
    </div>
  );
}

export default ViewTicket;