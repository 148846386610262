import './styles.scss';
import { FC } from 'react';
import { ModalMassActionsInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import { Trans } from 'react-i18next';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import BackendConstants from '../../../../../core/constants/backend-constants';

const ModalMassActions: FC<ModalMassActionsInterface> = (props) => {

  const LIMIT_TICKET_SELECTION = BackendConstants.LIMIT_MANAGER_TICKETS;

  const option1Class = props.actionType === 'add-tag' ? 'modal-mass-actions-content-options-item-selected' : '';
  const option2Class = props.actionType === 'resolve' ? 'modal-mass-actions-content-options-item-selected' : '';
  const option3Class = props.actionType === 'delete' ? 'modal-mass-actions-content-options-item-selected' : '';
  const option4Class = props.actionType === 'remove-agents' ? 'modal-mass-actions-content-options-item-selected' : '';
  const hasOptionClass = !props.actionType ? 'modal-mass-actions-content-options-info-empty' : '';
  const disabledOptionClass = props.data?.length > LIMIT_TICKET_SELECTION ? 'modal-mass-actions-content-options-item-disabled' : '';


  return (
    <Modal
    show={props.show}
    onHide={() => props.hide(false)}
    aria-labelledby=""
    size="sm"
    centered={true}
    dialogClassName="modal-mass-actions-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-mass-actions-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingRequest) && <Loading blur={true}/>}
        <div className="modal-mass-actions-content">
        { props.data &&
          <span className="modal-mass-actions-message">
            { props.data.length <= LIMIT_TICKET_SELECTION &&
              <Trans 
                i18nKey={props.data.length > 1 ? 'ModalMassActions:message_1_plural' : 'ModalMassActions:message_1_single'}
                values={{ selectedTickets: props.data.length }}
                components={[ <span /> ]} // Um elemento para cada valor em values
              />
            }
            { props.data.length > LIMIT_TICKET_SELECTION &&
              <Trans 
                i18nKey={'ModalMassActions:message_1_max_limit'}
                values={{ selectedTickets: props.data.length }}
                components={[ <span /> ]} // Um elemento para cada valor em values
              />
            }
          </span>
        }
          <div className="modal-mass-actions-content-section">
            <div className="modal-mass-actions-content-options">
              { props.showOption('add-tag') &&
                <span className={`modal-mass-actions-content-options-item ${option1Class} ${disabledOptionClass}`} onClick={() => props.selectActionType('add-tag')}>
                  {props.t('option_1')}
                </span>
              }
              { props.showOption('resolve') &&
                <span className={`modal-mass-actions-content-options-item ${option2Class} ${disabledOptionClass}`} onClick={() => props.selectActionType('resolve')}>
                  {props.t('option_2')}
                </span>
              }
              { props.showOption('delete') &&
                <span className={`modal-mass-actions-content-options-item ${option3Class} ${disabledOptionClass}`} onClick={() => props.selectActionType('delete')}>
                  {props.t('option_3')}
                </span>
              }
              { props.showOption('remove-agents') &&
                <span className={`modal-mass-actions-content-options-item ${option4Class} ${disabledOptionClass}`} onClick={() => props.selectActionType('remove-agents')}>
                  {props.t('option_4')}
                </span>
              }
            </div>
            <div className={`modal-mass-actions-content-options-info ${hasOptionClass}`}>
              { props.actionType === 'add-tag' &&
                <div className="modal-mass-actions-content-options-info-form">
                  <span className="modal-mass-actions-content-options-info-form-message">
                    {props.t(props.data.length > 1 ? 'info_1_plural' : 'info_1_single')}
                  </span>
                  <div className="modal-mass-actions-content-options-info-form-item">
                  <CustomSelectController 
                    type='pagination'
                    dropdownPosition='body'
                    hasFilter={true}
                    hasAction={props.getTagsSelect().tagsList?.length === 0}
                    tooltipAction={props.t('tags_add')}
                    action={props.createTag}
                    isMultiselect={true}
                    placeholder={props.t('tags_placeholder')}
                    placeholderFilter={props.t('tags_search')}
                    options={props.getTagsSelect().tagsList || []}
                    selectedMulti={props.getTagsSelect().selectedTags}
                    setSelectedMulti={props.getTagsSelect().setSelectedTags}
                    fetchMore={props.getTagsSelect().fetchTagsPagination}
                    hasMore={props.getTagsSelect().hasMoreTagsList}
                    loading={props.getTagsSelect().isLoadingTagsSelect}
                    search={props.getTagsSelect().fetchSearchTags}
                  />
                  </div>
                </div>
              }
              { props.actionType === 'resolve' &&
                <span className='modal-mass-actions-content-options-info-message'>
                  <span>{props.t(props.data.length > 1 ? 'info_2_plural' : 'info_2_single')}</span>
                  <span>{props.t('info_continue')}</span>
                </span>
              }
              { props.actionType === 'delete' &&
                <span className='modal-mass-actions-content-options-info-message'>
                  <span>{props.t(props.data.length > 1 ? 'info_3_plural' : 'info_3_single')}</span>
                  <span>{props.t('info_continue')}</span>
                </span>
              }
              { props.actionType === 'remove-agents' &&
                <span className='modal-mass-actions-content-options-info-message'>
                  <span>{props.t(props.data.length > 1 ? 'info_4_plural' : 'info_4_single')}</span>
                  <span>{props.t('info_continue')}</span>
                </span>
              }
              { !props.actionType && props.data.length <= LIMIT_TICKET_SELECTION &&
                <span className='modal-mass-actions-content-options-info-message'>
                  {props.t(props.data.length > 1 ? 'info_empty_plural' : 'info_empty_single')}
                </span>
              }
              { !props.actionType && props.data.length > LIMIT_TICKET_SELECTION &&
                <span className='modal-mass-actions-content-options-info-message-max-limit'>
                  <Trans 
                    i18nKey={props.data.length - LIMIT_TICKET_SELECTION > 1 ? 'ModalMassActions:info_max_limit_plural' : 'ModalMassActions:info_max_limit_single'}
                    values={{ maxLimit: LIMIT_TICKET_SELECTION, differenceLimit: props.data.length - LIMIT_TICKET_SELECTION }}
                    components={[ <span />, <span /> ]} // Um elemento para cada valor em values
                  />
                </span>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-mass-actions-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_close')}</button>
        <button className='modal-mass-actions-btn-submit' disabled={!props.isValid() || props.loadingRequest} onClick={props.handleSubmit}>{props.t('btn_submit')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMassActions;
