import './styles.scss';
import { FC } from "react";
import { ListOfTicketsInterface } from './indexModel';
import TableListComponentController from '../components/tableListComponent/indexController';
import ViewTicketController from '../viewTicket/indexController';
import DashCountersComponentController from '../components/dashCounters/indexController';
import ModalCreateTicketController from '../components/modalCreateTicket/indexController';
import ModalDeleteTicketController from '../components/modalDeleteTicket/indexController';
import ModalSendTicketCopyController from '../components/modalSendTicketCopy/indexController';
import ModalListTasksController from '../../homeTasks2/components/modalListTasks/indexController';
import ModalReopenTicketController from '../components/modalReopenTicket/indexController';
import ModalRecoveryDeletedTicketController from '../components/modalRecoveryDeletedTicket/indexController';
import FilteredViewBarController from '../../components-fixed/searchComponent/filteredViewBar/indexController';
import ModalExportAllController from '../components/modalExportAll/indexController';
import ModalMassActionsController from '../components/modalMassActions/indexController';
import { PagesType } from '../../components-fixed/sidebarAppComponent/indexModel';

const ListOfTickets: FC<ListOfTicketsInterface> = (props) => {

  const cssCompact = props.compactList ? 'compact-list' : '';
  // const cssHide = props.showList ? '' : 'list-tickets-compact-list-hide';
  // const cssWidth = props.sidebarOpened ? 'list-tickets-sidebar-opened' : 'list-tickets-sidebar-closed';
  const hasManageTickets: PagesType[] = ['ticket-list', 'history-chatbot'];

  return (
    <div className="list-tickets-page" 
      // Aqui não deve mexer no style. Ele deve pegar somente a altura do mainHeader - OK
      // style={{ height: `calc(100vh - ${props.getMainHeaderHeight()}px)`}}>
      >
      { props.showModalCreateTicket && <ModalCreateTicketController show={props.showModalCreateTicket} hide={props.closeModalCreateTicket} /> }
      { props.showModalDeleteTicket && <ModalDeleteTicketController show={props.showModalDeleteTicket} hide={props.closeModalDeleteTicket} /> }
      { props.showModalRecoveryDeletedTicket && <ModalRecoveryDeletedTicketController show={props.showModalRecoveryDeletedTicket} hide={props.closeModalRecoveryDeletedTicket} /> }
      { props.showModalReopenTicket && <ModalReopenTicketController show={props.showModalReopenTicket} hide={props.closeModalReopenTicket} /> }
      { props.showModalSendTicketCopy && <ModalSendTicketCopyController show={props.showModalSendTicketCopy} hide={props.closeModalSendTicketCopy} /> }
      { props.showModalListTasks && <ModalListTasksController show={props.showModalListTasks} hide={props.closeModalListTasks} /> }
      { props.showModalExportAll && <ModalExportAllController show={props.showModalExportAll} hide={props.closeModalExportAll} pageType={props.pageType} /> }
      { props.showModalManager && <ModalMassActionsController show={props.showModalManager} hide={props.closeModalManager} pageType={props.pageType} />}

      <div className="list-tickets-page-info">
        { props.showDashCounters() &&
          <div className="page-counters" ref={props.pageCountersRef}>
            <DashCountersComponentController pageType={props.pageType} handleClick={props.handleClickDashCounter} />
          </div>
        }
        { props.filters?.length > 0 && 
          <FilteredViewBarController pageType={props.pageType} filters={props.filters} filterMode={props.filterMode} clearSearch={props.clearSearchTickets} clearSpecificFilter={props.clearSpecificFilter} />
        }
      </div>
      {/* <div className={`list-tickets-page-content ${cssCompact} ${cssHide}`.trim()}> */}
      <div className={`list-tickets-page-content ${cssCompact}`.trim()}>
        <TableListComponentController 
          pageType={props.pageType}
          headers={props.listHeaders}
          currentLoggedUserID={props.currentLoggedUserID}
          data={props.listData}
          paginationDetails={props.paginationDetails}
          fetchMore={props.getTicketsPagination}
          isLoading={props.isLoading}
          errorLoading={props.errorLoading}
          reloadTickets={props.reloadTickets}
          selectItem={props.selectItem} 
          selectAllItems={props.selectAllItems}
          isSelectedItem={props.isSelectedItem} 
          selectedItemsLength={props.selectedItemsLength}
          hasSomeSelected={props.hasSomeSelected}
          hasSomeSelectedCheck={props.hasSomeSelectedCheck}
          isAllSelectedItems={props.isAllSelectedItems}
          hasItemCheckSelection={hasManageTickets.includes(props.pageType)} // Mostrar checkbox de seleção
          isItemCheckSelectionDisabled={props.avoidTicketSelection()}
          hasExportAll={true}
          hasManageSelected={hasManageTickets.includes(props.pageType)} // Modal de ações em massa
          exportAll={props.openModalExportAll}
          manageSelected={props.openModalManager}
          clearSelected={props.clearSelected}
          clearPageSelected={props.clearPageSelected}
          hasOptionalAction={true}
          optionalActionField='who_is_viewing'
          openTicket={props.openTicket}
          selectedItemId={props.selectedItemId}
          compactList={props.compactList}
          showList={props.showList}
          setShowList={props.setShowList}
          getOffsetHeight={props.getOffsetHeight}
          offsetHeight={props.offsetHeight}
          getMainHeaderHeight={props.getMainHeaderHeight}
          popoverItemAction={props.popoverItemAction}
          itemView={props.itemView}
          getIsItemDisabled={props.getIsItemDisabled}
        />
        { 
          props.compactList && 
          <ViewTicketController
            selectedItemId={props.selectedItemId} 
            showList={props.showList}
            pageType={props.pageType}
            reloadTickets={props.reloadTickets}
            removeSelectedItem={props.removeSelectedItem}
          />
        }
      </div>
    </div>
  );
}

export default ListOfTickets;